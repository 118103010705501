import { RouteConfig } from 'vue-router'

const AboutUsRoute: Array<RouteConfig> = [
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: { authorize: [] },
    component: () => import('@/views/AboutUs/index.vue'),
  },
]

export default AboutUsRoute
